<template>
  <div class="app-container">
    <eHeader ref="header" :query="query" :sites="sites" />
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="id"/>
      <el-table-column prop="platformId" label="所属站点" width="200">
         <template slot-scope="scope">
          <el-select v-model="scope.row.siteId" filterable placeholder="所属站点"  disabled>
            <el-option
              v-for="item in sites"
              :key="item.id"
              :value="item.id"
              :label="item.siteName"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="platformName" label="所属平台"/>
      <el-table-column prop="serverId" label="服务器ID" />
      <el-table-column prop="serverName" label="服务器名称"/>
      <el-table-column prop="checkUrl" label="校验地址" width="320"/>
       <el-table-column prop="queryUrl" label="查询地址" width="320"/>
      <el-table-column prop="valid" label="状态">
        <template slot-scope="scope">
          <span>{{ scope.row.valid==1? '启用' :'禁用'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150px" align="center">
        <template slot-scope="scope">
          <edit v-if="checkPermission(['ADMIN','MT4SERVER_ALL','MT4SERVER_EDIT'])" :data="scope.row" :sup_this="sup_this" :sites="sites"/>
          <el-popover
            v-if="checkPermission(['ADMIN','MT4SERVER_ALL','MT4SERVER_DELETE'])"
            :ref="scope.row.id"
            placement="top"
            width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
  import { getSitesData } from '@/api/cms/srSite'
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { del } from '@/api/community/srMt4Server'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/community/platmgr/serverlist/header'
import edit from '@/components/community/platmgr/serverlist/edit'
export default {
  name:'serverlist',
  components: { eHeader, edit },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      sup_this: this,
      sites: [],
    }
  },
  mounted() {
    this.getSites();
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = 'community/crm/srMt4Server'
      const sort = 'id,desc'
      this.params = { page: this.page, size: this.size, sort: sort }
      let header = this.$refs.header;
      const query = this.query;
      const platformId = query.platformId
      const serverName = query.serverName
      const valid = query.valid;
      if (header.$refs.searchcommunity.siteId) {
        this.params["siteId"] = header.$refs.searchcommunity.siteId;
      }
      if (platformId !== "" && platformId !== null) {
        this.params["platformId"] = platformId;
      }
      if (serverName !== "" && serverName !== null) {
        this.params["serverName"] = serverName;
      }
      if (valid !== "" && valid !== null) {
        this.params["valid"] = valid;
      }
      return true
    },
    getSites() {
      const params = {page:0,size:1000}
      getSitesData(params).then(res => {
        this.sites = res.content;
      });
    },
    subDelete(id) {
      this.delLoading = true
      del(id).then(res => {
        this.delLoading = false
        this.$refs[id].doClose()
        this.init()
        this.$notify({
          title: '删除成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[id].doClose()
        console.log(err.response.data.message)
      })
    }
  }
}
</script>

<style scoped>

</style>
