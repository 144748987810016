<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增' : '编辑'" width="700px">
    <el-form ref="form" :model="form"  size="small" label-width="200px">
      <el-form-item label="所属平台">
        <el-select v-model="form.siteId" filterable placeholder="请选择复制站点" style="width: 150px;" @change="getPlatformList(true)">
          <el-option
            v-for="item in sites"
            :key="item.id"
            :label="item.siteName"
            :value="item.id">
          </el-option>
        </el-select>
        <el-select v-model="form.platformId" placeholder="所属平台" style="width: 150px;">
          <el-option
            v-for="item in platList"
            :key="item.platformId"
            :label="item.platformName"
            :value="item.platformId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="服务器ID">
        <el-input v-model="form.serverId" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="服务器名称">
        <el-input v-model="form.serverName" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="服务器校验地址">
        <el-input v-model="form.checkUrl" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="服务器查询地址">
        <el-input v-model="form.queryUrl" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="是否启用">
        <el-radio v-model="form.valid" label= 1>启用</el-radio>
        <el-radio v-model="form.valid" label= 2>禁用</el-radio>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from '@/api/community/srMt4Server'
import { initData } from '@/api/data'
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    },
    sites: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      loading: false, dialog: false,platList:[],selectedSiteId:2,
      form: {
        id: '',
        siteId:'',
        platformId: 0,
        serverId: '',
        serverName: '',
        checkUrl: '',
        queryUrl: '',
        valid: '1'
      }
    }
  },
  created() {

  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.loading = true
      if (this.isAdd) {
        this.doAdd()
      } else this.doEdit()
    },
    doAdd() {
      add(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      edit(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {
        id: '',
        siteId:'',
        platformId: 1,
        serverId: '',
        serverName: '',
        checkUrl: '',
        queryUrl: '',
        valid: '1'
      }
    },
    getPlatformList(isChange){
      const sort = 'id,desc'
      let params = { siteId:this.form.siteId,page: 0, size: 300, sort: sort }
      initData('community/crm/srPlatformInfo', params).then(res => {
        this.platList = res.content
        if(isChange) {
          this.form.platformId = this.platList[0].id
        }
      }).catch(err => {
      })
    }
  }
}
</script>

<style scoped>

</style>
