<template>
  <div class="head-container">
    <!-- 搜索 -->
    <search-community ref="searchcommunity"></search-community>
    <el-input v-model="query.platformId" clearable placeholder="输入平台ID查询" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-input v-model="query.serverName" clearable placeholder="输入服务器名称查询" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-select v-model="query.valid" clearable placeholder="状态" class="filter-item" style="width: 130px" @change="toQuery">
      <el-option v-for="item in validOptions" :key="item.key" :label="item.display_name" :value="item.key"/>
    </el-select>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <!-- 新增 -->
    <div style="display: inline-block;margin: 0px 2px;">
      <el-button
        v-if="checkPermission(['ADMIN','MT4SERVER_ALL','MT4SERVER_CREATE'])"
        class="filter-item"
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="$refs.form.dialog = true">新增</el-button>
      <eForm ref="form" :is-add="true" :sites="sites"/>
    </div>
  </div>
</template>

<script>
  import searchCommunity from '@/components/common/searchCommunity'
import checkPermission from '@/utils/permission' // 权限判断函数
import eForm from './form'
export default {
  components: { eForm,searchCommunity },
  props: {
    query: {
      type: Object,
      required: true
    },
    sites: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      queryTypeOptions: [
        { key: 'serverId', display_name: 'MT4服务器Id' },
        { key: 'serverName', display_name: 'MT4服务器名称' },
      ],
      validOptions:[
       { key: '1', display_name: '启用' },
        { key: '2', display_name: '禁用' },
      ]
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$parent.page = 0
      this.$parent.init()
    }
  }
}
</script>
